.date-con .react-datepicker__input-container input{
    background-color: rgb(45, 44, 44);
    color:whitesmoke;
    border: none;
    padding: 10px;
    font-size: medium;
    text-align: center;
    margin: auto;
}

.date-con .react-datepicker__input-container {
    position:static;
}