.log-con{
    margin:20px;
}

.log-con .no-rec{
    font-size: larger;
    font-weight: 600;
}

.log-con .container{
    margin :0px auto;
}