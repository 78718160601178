

.prod-actions button {
  width: fit-content;
  padding: 6px 15px;
}

.prod-actions button:first-child {
  margin-right: 12px;
}
.prod-actions {
  margin: 5px 15px;
}

.con-pop .pop-btn {
  display: flex;
  justify-content: space-around;
}

.con-pop .pop-btn button {
  width: fit-content;
  padding: 8px;
  background-color: rgb(8, 153, 8);
}

.con-pop .pop-btn button:last-child {
  background-color: #f01936;
}

.product-con .msg {
  display: flex;
  margin-bottom: 15px;
  margin-left: 15px;
  width: fit-content;
  background-color: rgb(181, 185, 181);
  align-items: start;
  border-radius: 5px;
  padding: 6px 12px;
  height: fit-content;
  justify-content: space-between;
}

.product-con .msg .fail {
  color: rgb(228, 7, 7);
}

.product-con .msg .success {
  color: rgb(3, 112, 3);
}
.product-con .msg button {
  padding: 0px 5px;
  height: 10px;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 0px;
  font-weight: bolder;
  background-color: inherit;
}

.product-con .title {
  font-size: xx-large;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: #64c7f6;
}
