.chip-input {
  display: inline;
  border: 1px solid black;
  border-radius: 5px;
  background-color: inherit;
  margin: 0px;
  width: 100%;
}
.chip-component {
  position: relative;
  width: 100%;
  outline: none;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: medium;
  padding: 6px;
  /* background-color: aliceblue; */
  color: rgb(23, 145, 186);
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chip {
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px 8px;
  padding-bottom: 2px;
  margin-right: 8px;
  display: inline-block;
  margin-bottom: 4px;
}

.chip span:first-child {
  margin-left: 8px;
  color: rgb(75, 74, 74);
  font-size: large;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 98%;
  left: 0%;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  outline: none;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: medium;
  padding: 6px;
  background-color: aliceblue;
  color: rgb(23, 145, 186);
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1;
}

.options .option {
  padding: 6px;
}
.options .option:hover {
  background-color: rgb(196, 194, 194);
  cursor: default;
}
