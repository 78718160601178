.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(242, 235, 235, 0.2);
  cursor: pointer;
  z-index: 9;
}
.con-pop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1c2130;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  min-width: 250px;
  padding: 20px 25px;
  border-radius: 10px;
  font-weight: 700;
  text-align: center;
  cursor: auto;
  opacity: 1;
  z-index: 10;
}

.center {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 3px;
  height: 30px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 8px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.modal .wave {
  height: 30px;
  margin: 2px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.overlay .dynamic {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  color: rgb(232, 235, 236);
}
