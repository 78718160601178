.error-head{
    font-size: larger;
    text-align: center;
    margin-top: 40px;
    /* color: white; */
}
.error-header{
    font-weight: bolder;
}
.error-head a{
    /* color:white; */
    color: rgb(125,211,252);
}