.checkbox-grp {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 48px;
}

.checkbox-grp input {
  display: inline;
  width: auto;
  margin-bottom: 0px;
  margin-right: 12px;
}
.checkbox-grp label {
  display: inline;
  width: auto;
  margin-bottom: 0px;
  margin-right: 12px;
}

.select-button {
  padding: 6px 16px;
  font-size: large;
  width: fit-content;
}

.close-con {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.close-con > button {
  background-color: transparent;
  color: azure;
  padding: 0px;
  margin: 0px;
  margin-right: 8px;
  width: fit-content;
}

.load {
  font-size: larger;
  font-weight: bold;
}
