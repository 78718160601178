.status {
  display: flex;
  margin: auto;
  margin-bottom: 24px;
  width: fit-content;
  background-color: rgb(181, 185, 181);
  align-items: start;
  border-radius: 5px;
  padding: 6px 12px;
  height: fit-content;
  justify-content: space-between;
}
.status .fail {
  color: rgb(228, 7, 7);
}
.status .success {
  color: rgb(3, 112, 3);
}
.status button {
  padding: 0px 5px;
  height: 10px;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 0px;
  font-weight: bolder;
  background-color: inherit;
}
