.menu-container {
    background-color: white;
    padding: 10px 20px;
    border-bottom: 2px solid #eee;
    position: sticky;
    top: 60px;
    z-index: 20;
}

.menu-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.menu-item {
    margin-right: 20px;
    font-size: 18px;
    position: relative;
}

.menu-item .menu-link {
    text-decoration: none;
    color: #333;
    padding: 5px 10px;
    transition: all 0.3s ease;
}

.menu-item:hover .menu-link {
    color: #007bff;
    /* Change text color on hover */
}

.menu-item.active .menu-link {
    /* color: white; */
    border-bottom: 4px solid #007bff;
    color: #007bff;
    /* border-radius: 4px; */
    padding: 5px 10px;
    font-weight: bold;
}

.menu-item:last-child {
    margin-right: 0;
    /* Remove margin on last item */
}

.submenu-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 20;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.menu-item:hover > .submenu-container {
    display: block;
}

.submenu-container:hover {
    display: block;
}

.submenu-container .submenu{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    margin: 0;
}

.submenu-container .submenu li{
    list-style: none;
}

.submenu-container h5 {
    margin: 15px
}

.submenu-container ol{
    padding-left: 10px;
    margin-right: 20px;
}

.submenu-container .submenu-item {
    font-size: 16px;
    color: gray;
    margin: 8px
}

.submenu-container .submenu-item:hover{
    color: black;
    cursor: pointer;
}

.function-title{
    font-size: 16px;
    margin: 10px 20px;
    font-weight: 600;
    color: #454545;
}

