.loader {
  border: 5px solid #f3f3f3;
  /* Light gray background */
  border-top: 5px solid #3498db;
  /* Blue color for the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}


/* Full-page centered loader */
.loader-container.full-size {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.5); */
  /* Optional, to dim the background */
  z-index: 9999;
  /* To ensure the loader is on top of other content */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}